.ql-editor {
  height: auto !important;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  flex: 1;
}
.ql-container {
  border: none !important;
  height: auto !important;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: #a298b7;
  height: 42px;
  flex: 0;
}
.ql-fill {
  fill: white !important;
  opacity: 0.5;
}

.ql-stroke {
  stroke: white !important;
  opacity: 0.2;
}

.ql-picker-label {
  color: white;
  opacity: 0.7;
}

.ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-picker-label.ql-active,
.ql-active .ql-stroke,
.ql-active .ql-fill {
  color: white !important;
  opacity: 1;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-radius: 0.25rem;
}
.ql-picker-options {
  background-color: #a298b7 !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #a298b7;
  font-family: Poppins;
  font-style: normal;
}

.quill {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
}

.ql-snow .ql-editor h1 {
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  font-family: poppins;
}

.ql-snow .ql-editor h2 {
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  font-family: poppins;
}
.ql-snow .ql-editor :is(h3, h4, h5, h6) {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  font-family: Poppins;
}
.ql-snow .ql-editor .lg-secondary :is(h3, h4, h5, h6) {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  color: theme("colors.secondary.DEFAULT");
}

.ql-snow .ql-editor {
  font-family: Noto Sans;
  color: theme("colors.secondary.DEFAULT");
}
